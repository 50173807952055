import React, { Component } from "react";

let values = [
  {
    id: 28,
    category: "Accounting",
    skillName: "Accounting ",
    skillDescription:
      "Accountants have to be able to communicate with other departments, colleagues, and clients",
  },
  {
    id: 29,
    category: "Accounting",
    skillName: "Detail Oriented",
    skillDescription:
      "A lot of accounting is about paying attention to the little details",
  },
  {
    id: 30,
    category: "Accounting",
    skillName: "Managing the Loan Details",
    skillDescription:
      "Accounting jobs often require knowledge of a variety of computer programs and systems",
  },
  {
    id: 19,
    category: "Banking",
    skillName: "Attention to detail",
    skillDescription:
      "it is important for them to exhibit certain skills…n it comes to mathematical and analytical ability",
  },
  {
    id: 20,
    category: "Banking",
    skillName: "Organized Mind",
    skillDescription:
      "Bank employees to have a well-organized mind and attention for details",
  },
  {
    id: 21,
    category: "Banking",
    skillName: "Leadership",
    skillDescription:
      "It is very important for Bank officers to exhibit …onfidence among subordinates as well as customers",
  },
  {
    id: 22,
    category: "Banking",
    skillName: "Management",
    skillDescription:
      "Employee management principles and innovative tech… keep the bank running with the limited resources",
  },
  {
    id: 23,
    category: "Finance",
    skillName: "Accounting Skills",
    skillDescription:
      "Techniques and abilities that allow record, and manage financial transactions effectively",
  },
  {
    id: 24,
    category: "Finance",
    skillName: "Business Intelligence",
    skillDescription:
      "Finance professionals play a vital role in helping businesses make smart decisions",
  },
  {
    id: 25,
    category: "Finance",
    skillName: "Financial Modeling",
    skillDescription:
      "This is the process of representing a real financial situation through abstract methods",
  },
  {
    id: 26,
    category: "Finance",
    skillName: "Financial Management",
    skillDescription:
      "It refers to organizing, planning, directing, and controlling financial activities",
  },
  {
    id: 2008,
    category: "HR",
    skillName: "Database assist",
    skillDescription: "to manage database details",
  },
  {
    id: 10,
    category: "HR",
    skillName: "Communication skills",
    skillDescription:
      "The most frequently mentioned skill in HR job openings is communication skills",
  },
  {
    id: 11,
    category: "HR",
    skillName: "Administrative expert",
    skillDescription: "Administrative tasks remain a major part of the HR role",
  },
  {
    id: 12,
    category: "HR",
    skillName: "Recruitment and selection",
    skillDescription:
      "A substantial part of recruitment and selection is interviewing candidates",
  },
  {
    id: 13,
    category: "HR",
    skillName: "Time Management",
    skillDescription:
      "Drawing up employment contracts and managing payroll and insurance plans are time-sensitive tasks",
  },
  {
    id: 14,
    category: "HR",
    skillName: "Records Management",
    skillDescription:
      "HR teams manage information and documents, like em…oyment agreements in physical and digital formats",
  },
  {
    id: 15,
    category: "HR",
    skillName: "Calendar management",
    skillDescription:
      "In-between meetings with colleagues and executives…ssionals have to squeeze in other important tasks",
  },
  {
    id: 1,
    category: "IT",
    skillName: "The combined action of a ",
    skillDescription: "Teamwork",
  },
  {
    id: 2,
    category: "IT",
    skillName: "Communication skill",
    skillDescription:
      "Communication skills allow you to give and receive information",
  },
  {
    id: 3,
    category: "IT",
    skillName: "Leadership skills",
    skillDescription:
      "Leadership skills are the strengths,abilities indi…emonstrate that help the oversee processes, goals",
  },
  {
    id: 5,
    category: "IT",
    skillName: "C Sharp",
    skillDescription:
      "Configure, create, test, support and convey work area, custom web, and portable applications",
  },
  {
    id: 6,
    category: "IT",
    skillName: "Java",
    skillDescription:
      "Design, build, and maintain efficient, reusable, and reliable Java code",
  },
  {
    id: 7,
    category: "IT",
    skillName: "MVC",
    skillDescription:
      "The reusing of code and parallel development,More Effective Functionalities",
  },
  {
    id: 8,
    category: "IT",
    skillName: "React",
    skillDescription: "Front End Designing",
  },
  {
    id: 9,
    category: "IT",
    skillName: "SQL",
    skillDescription: "Stored Procedure",
  },
  { id: 2010, category: "IT", skillName: "IT", skillDescription: "Infotech" },
  {
    id: 2006,
    category: "IT",
    skillName: "Info tech",
    skillDescription: "Inforamation Technology",
  },
  {
    id: 2007,
    category: "IT",
    skillName: "Information technologies",
    skillDescription: "to manage Information details",
  },
  {
    id: 27,
    category: "Management",
    skillName: "Analytical1",
    skillDescription:
      "Auditors might analyze data to find instances of people misusing funds",
  },
  {
    id: 2009,
    category: "Production",
    skillName: "C.A",
    skillDescription: "Accountant",
  },
  {
    id: 16,
    category: "Production",
    skillName: "Reliability",
    skillDescription:
      "Reliability on the job is a key skill for production and manufacturing employees",
  },
  {
    id: 17,
    category: "Production",
    skillName: "Trainability",
    skillDescription:
      "Production and manufacturing are ever-changing in terms of technology and procedure",
  },
  {
    id: 18,
    category: "Production",
    skillName: "Attention to detail",
    skillDescription:
      "The ability to focus while maintaining important details is vital for success",
  },
].sort();
export default class TestDrop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      btnc: [],
      btnfilt: [],
      finalValues: [],
      final: [],
      Indexvalue: 0,
      BtnIndexvalue: 0,
    };
    this.loop = this.loop.bind(this);
    this.previous = this.previous.bind(this);
  }

  loop() {
    let btncnt = this.state.btnc;
    let btnfilt = this.state.btnfilt;
    var rowcount = Math.ceil(values.length / 10);
    for (var i = 0; i < rowcount; i++) {
      btncnt.push(i);
      btnfilt.push(i);
    }
    this.setState({ btncnt });
    this.setState({ btnfilt });
  }

  index = (index) => {
    //let final=[...this.state.finalValues]
    this.setState({ Indexvalue: index });
    let finalValues = values.slice(index * 10, index * 10 + 10);
    this.setState({ finalValues });
  };

  forward = () => {
    let btnfilt = this.state.btnfilt;
  };

  previous = () => {
    let index = this.state.Indexvalue;
    if (index === 0) {
      let finalValues = values.slice(index * 10, index * 10 + 10);
      this.setState({ finalValues });
    } else {
      let finalValues = values.slice(index * 10 - 10, index * 10 - 10 + 10);
      this.setState({ finalValues });
      this.setState({ Indexvalue: index - 1 });
    }
  };

  Next = () => {
    let index = this.state.Indexvalue;

    var rowcount = Math.ceil(values.length / 10);
    let finalValues = values.slice(index * 10 - 10, index * 10 - 10 + 10);
    this.setState({ finalValues });
    if (index < rowcount) {
      let finalValues = values.slice(index * 10, index * 10 + 10);
      this.setState({ finalValues });

      this.setState({ Indexvalue: index + 1 });
    }
    //    else{
    //     let  finalValues = values.slice(index*10-10,index*10-10+10)
    //     this.setState({finalValues})
    //     this.setState({Indexvalue:index-1})

    //    }
  };

  componentDidMount() {
    let finalValues = values.slice(0, 10);
    let btncnt = this.state.btnc;
    var rowcount = Math.ceil(values.length / 10);
    for (var i = 0; i < rowcount; i++) {
      btncnt.push(i);
    }
    this.setState({ btncnt });
    this.setState({ finalValues });
  }

  render() {
    return (
      <>
        {/* <button onClick={this.loop}>hijinv</button><br/> */}
        <div className="container">
          <div className="row">
            <div className="col-lg-2"></div>
            <div className="col-lg-8">
              <div style={{ height: "230px" }}>
                {this.state.finalValues.map((val) => (
                  // {this.state.finalValues.map(val=>
                  <>
                    <span>{val.id}</span>&nbsp;&nbsp;
                    <span>{val.skillName}</span>
                    <br />
                  </>
                ))}
              </div>

              <div className=" ">
                <button> Backward </button>
                <button
                  style={{
                    color: "green",
                    border: "1px transparent",
                    margin: "8px",
                  }}
                  onClick={() => this.previous()}
                >
                  Previous
                </button>

                {this.state.btnc.map((val) => (
                  <button
                    style={
                      this.state.Indexvalue === val
                        ? {
                            borderRadius: "21px",
                            border: "1px transparent",
                            margin: "8px",
                            backgroundColor: "#ababab",
                          }
                        : {
                            borderRadius: "21px",
                            border: "1px transparent",
                            margin: "8px",
                          }
                    }
                    onClick={() => this.index(val)}
                  >
                    {val + 1}
                  </button>
                ))}

                <button
                  style={{
                    color: "green",
                    border: "1px transparent",
                    margin: "8px",
                  }}
                  onClick={() => this.Next()}
                >
                  Next
                </button>
                <button> Forward </button>
              </div>
            </div>
            <div className="col-lg-2"></div>
          </div>
        </div>
      </>
    );
  }
}
