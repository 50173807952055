import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class Su extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }

  render() {
    var w = window.innerWidth;

    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal oneFieldPopMobile"
            }
            style={{ padding: "1%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h4>The Announcement will be published!!</h4>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link to="/AnnouncementPreview" type="button" className="btn">
                  Ok
                </Link>
                &nbsp;&nbsp;&nbsp;
                {/* <button  className="btn-Secondary"  onClick={this.props.close}>No</button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
