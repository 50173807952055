import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
export default class RegMsgPop extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }

  handle() {}
  render() {
    var w = window.innerWidth;

    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal oneFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={w > 768 ? { padding: "3%" } : { padding: "4%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h4> OTP sent successfully. Please check your Email </h4>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <button className="btn" onClick={this.props.close}>
                  Ok
                </button>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
