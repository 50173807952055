import React from "react";

const GroupTitlePopup = (props) => {
  return (
    <div className="GTpopup-box">
      <div className="GTbox">
        <span className="GTclose-icon" onClick={props.handleClose}>
          x
        </span>

        {props.content}
      </div>
    </div>
  );
};
export default GroupTitlePopup;
