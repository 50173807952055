import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

export default class AddDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }

  handle() {}
  render() {
    return (
      <>
        <div className="pop">
          <div
            className="popinnernormal"
            style={{ width: "50%", padding: "2%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h5>OTP verified successfully. Proceed to Login</h5>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link to="/Login_form" type="button" className="btn">
                  Ok
                </Link>
                &nbsp;&nbsp;&nbsp;
                {/* <button  className="btn-Secondary"  onClick={this.props.close}>No</button> */}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
