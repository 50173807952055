import React from "react";
import ReactDOM from "react-dom";
import {BrowserRouter } from "react-router-dom";
import * as timeago from 'timeago.js';

// import it first.
import vi from 'timeago.js/lib/lang/vi';
import App from "./App";
// import * as serviceWorker from "./serviceWorker";
import JavascriptTimeAgo from 'javascript-time-ago'
 
// The desired locales.
import en from 'javascript-time-ago/locale/en'
import ru from 'javascript-time-ago/locale/ru'
 
// Initialize the desired locales.
JavascriptTimeAgo.locale(en)
JavascriptTimeAgo.locale(ru)



// register it.
timeago.register('vi', vi);
ReactDOM.render(
    <BrowserRouter> 
        < >
     <App/>
        </>
    
    </BrowserRouter>,
    document.getElementById("root")
);

// serviceWorker.unregister();