import React, { Component } from "react";
export default class Planet extends Component {
  render() {
    return (
      <div>
        <div className="LoadBgbox">
          <div className="loaderbox">
            <div className="square"></div>
            <div className="square"></div>
            <div className="square last"></div>
            <div className="square clear"></div>
            <div className="square"></div>
            <div className="square last"></div>
            <div className="square clear"></div>
            <div className="square "></div>
            <div className="square last"></div>
            <br />
          </div>
          <div style={{ position: "absolute", top: "50%", width: "100%" }}>
            <h2 style={{ color: "#fffffd" }}>Setting Up Environment</h2>
          </div>
        </div>
      </div>
    );
  }
}
