import React, { Component } from "react";
//import avatar from "../../assets/img/cover.jpeg";
//import ClimbingBoxLoader from 'react-spinners/ClimbingBoxLoader'
import logo from "../Assets/images/OATlogo.png";
//import CustomInput from "../../components/CustomInput/CustomInput";
//import '../Reg_Log/SiginStyle.css'
import "../Css/SigninStyle.css";

const LogoCompo = () => {
  return (
    <div>
      <div>
        <div className="bgImg" />
        <div className="content">
          <br />
          <div className="row">
            <div className="col-sm-12" style={{ textAlign: "center" }} id="adjustlogo">
              <img
                alt="logo"
                src={logo}
                style={{ height: "100px", width: "100px" }}
              />
              &nbsp;&nbsp;&nbsp;
              <h3 style={{ color: "white" }}>One Awesome Team</h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default LogoCompo;