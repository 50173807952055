import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";
import SuccessPopup1 from "./SuccessPopup1";

export default class Chatconfirm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      EditSkillModel: false,
      EditSkillValues: [],

      Id: "",
      firstName: "",
      Skillname: "",
    };
  }
  EditSkillModel = (e, fn) => {
    if (document.getElementById("selectall")) {
      document.getElementById("selectall").checked = false;
    }
    if (document.getElementsByName("AddSkills")) {
      var checkboxes = document.getElementsByName("AddSkills");
      for (var i = 0; i < checkboxes.length; i++) {
        checkboxes[i].checked = false;
        document
          .getElementById("delete")
          .setAttribute("style", "visibility: hidden;");
        document
          .getElementById("deletecount")
          .setAttribute("style", "visibility: hidden;");
      }
    }

    this.setState({
      EditSkillValues: {
        id: e,
        firstName: fn,
      },
    });
    this.setState((prevState) => ({
      EditSkillModel: !prevState.EditSkillModel,
    }));
  };
  render() {
    var w = window.innerWidth;

    return (
      <div className="pop">
        {this.state.EditSkillModel ? (
          <SuccessPopup1
            id=""
            values={this.state.EditSkillValues}
            close={() => this.EditSkillModel()}
          />
        ) : null}
        <div
          className={
            w > 768
              ? "popinnernormal oneFieldPop"
              : "popinnernormal threeFieldPopMobile"
          }
          style={w > 768 ? { padding: "2%" } : { padding: "4%" }}
        >
          <form autoComplete="off">
            <h5 style={{ color: "rgb(36 66 42)", textAlign: "center" }}>
              Are you sure you want to Add a User?{" "}
            </h5>
            <br />
            <Link
              className="btn"
              href
              onClick={(e) => {
                e.preventDefault();
                this.EditSkillModel();
              }}
            >
              Yes
            </Link>{" "}
            <Link href onClick={this.props.close} className="btn">
              No
            </Link>
          </form>
        </div>
      </div>
    );
  }
}
