import React, { Component } from "react";
export default class LoadingPage extends Component {
  render() {
    return (
      <div>
        <div className="LoadBg">
          {/* <h1>{document.documentElement.getBoundingClientRect().height}</h1> */}

          <div style={{ position: "relative", left: " 50%", top: "50%" }}>
            <div className="lds-ring">
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
