import React from "react";
// import './Effect.css'
import "../Css/Effect.css";
function Dummyfntn() {
  return (
    <div>
      <div className="all">
        <nav className="nav-main">
          <label>
            <div className="title">
              <p className="head">
                &nbsp;&nbsp; &nbsp;&nbsp;
                <span className="icons">
                  <i className="fas fa-angle-left"></i>
                </span>{" "}
                &nbsp;&nbsp;Menus
              </p>
            </div>
            <input type="checkbox" />
            <ul>
              {/* <li><a><p><i className="fa fa-times pointer"></i></p></a></li> */}
              <li>
                <button type="button"> Home</button>
              </li>
              <br />
              <li>
                <button type="button">Long Title</button>
              </li>
              <br />
              <li>
                <button type="button">Products</button>
              </li>
              <br />
              <li>
                <button type="button">Services</button>
              </li>
              <br />
              <li>
                <button type="button">Contact Us</button>
              </li>
            </ul>
          </label>
        </nav>
      </div>
      {/* <header></header> */}
      {/* <section>
  <div className="centered"><i className="fa fa-bars"></i>
    <h1>Pure CSS Menu Drawer</h1>
    <p>Having some fun with the&nbsp;&nbsp;<span className="code">:checked</span><span className="code">+</span>&nbsp;&nbsp; pseudo selectors.</p>
  </div>
</section> */}
      {/* <footer></footer>   */}
    </div>
  );
}
export default Dummyfntn;
