import React, { Component } from 'react'
import logo from "../Assets/images/OATlogo.png";
import LaptopGirl from '../../images/LaptopGirl.webp'
import { Link } from "react-router-dom";


export default class LandingPage extends Component {

  componentDidMount() {
    sessionStorage.clear();
    localStorage.clear()
  }
  
    render() { 
      var w = window.innerWidth;
        return (
            <div className="backimg">
  <div className="row">
    
      <div className="col-lg-6"  >
      <div className="row">
      <div className="col-lg-2" style={{alignItems: 'center',justifyContent: 'center',display: 'flex'}}>
      <img alt="logo" src={logo} style={{height:"70px", width:"70px",marginTop:"10px",marginLeft:"12px",marginTop:"10px",marginRight:"10px"}}/>
      </div>

      <div className={"col-lg-8 landtextS"}>
    <h3 className="txtOAT" >One Awesome Team</h3>
</div>


<div className="col-lg-2" ></div>

    </div>
    
    </div>



    <div className="col-lg-6">
    <div className="row">
    <div className="col-lg-5"></div>
    <div className="col-lg-7" style={{alignItems: 'center',justifyContent: 'center',display: 'flex'}}>

    <div style={{margin:"25px 0px 25px 10px"}}>
    <Link className="butland" to='' id="get-demo" >Get Demo</Link> 
    <Link className="butland" to='/Login_form' id="login-btn">Login</Link> 

</div>
</div>

    </div>




    </div>
    </div>                
  


    <div className="container" id="container-landing">
<div className="my-moon"></div>
<div className="col-lg-12" id='landing-row'>
<div className="rowland">  

<div className="content-wrapper col-lg-6 ">
  <img alt="girlpicture" id='girlpicture' style={{width:"55%"}}src={LaptopGirl} />
</div>
<div className="content-wrapper col-lg-6 " id="landing-page">
<div>
<h3  id='HeaderA1'> <h3>Engage</h3><h3>Nurture</h3><h3>Motivate</h3></h3>
{/* <h3  id='HeaderA1'> Engage&nbsp;&nbsp;&nbsp;Nurture&nbsp;&nbsp;&nbsp;Motivate</h3> */}
  <h5 id='HeaderA2' >Let's begin with your Entity</h5>
<Link to="/Sign-up" type="button" className="butland" id="get-start" >Get Started</Link>
<br></br>
</div>
</div>
</div>
</div>
</div>


<div className="footerhome" style={{backgroundColor:"#e6e5d305",color:"white"}}>
  <p>Contact us: contact@oneawesometeam.com</p>
</div>
            </div>
        )
    }
}
