import React from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const CommonSuccess = (props) => {
  const w = window.innerWidth;

  return (
    <>
      <div className="pop">
        <div
          className={
            w > 768
              ? "popinnernormal threeFieldPop"
              : "popinnernormal threeFieldPopMobile"
          }
          style={w > 768 ? { padding: "3%" } : { padding: "4%" }}
        >
          <h4 style={{ fontSize: "18px" }}>{props.message}</h4>
          <br />
          <div className="row">
            {props.status === "Success" ? (
              <div className="col-lg-12">
                <Link
                  type="button"
                  className="btn"
                  to="/Team"
                  onClick={props.close}
                >
                  Ok
                </Link>
                &nbsp;&nbsp;&nbsp;
              </div>
            ) : (
              <div className="col-lg-12">
                <button className="btn-Secondary" onClick={props.close}>
                  Close
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default CommonSuccess;
