import React, { Component } from "react";
import { Link } from "react-router-dom";
export default class AddDesignationModel extends Component {
  constructor(props) {
    super(props);

    this.state = {
      formErrors: {},
      Business: [],
    };
  }
  Validation() {
    let formErrors = {};
    let formIsValid = true;
    var DesignationName = document.getElementsByName("DesignationName");
    var OtherDesignationName = document.getElementById("designationtext").value;
    if (!DesignationName[0].value) {
      formIsValid = false;
      formErrors["DesignationName"] = "Designation Name  is required";
    } else if (DesignationName[0].value === "Others") {
      if (!OtherDesignationName) {
        formIsValid = false;
        formErrors["OtherDesignationName"] = "Designation Name is required";
      }
    }

    this.setState({ formErrors: formErrors });

    //document.getElementById("add-row").style.display = "none";
    return formIsValid;
  }

  render() {
    var w = window.innerWidth;

    return (
      <>
        <div className="pop">
          <div
            className={
              w > 768
                ? "popinnernormal threeFieldPop"
                : "popinnernormal threeFieldPopMobile"
            }
            style={w > 768 ? { padding: "3%" } : { padding: "4%" }}
          >
            {/* <button  id="close" style={{float:"right",backgroundColor:"#a0a3a305",color:"#797070",border:"1px solid #a3a5a505"}}  ><CloseIcon/></button> */}
            <h4>{this.props.message}</h4>
            <br />
            <div className="row">
              <div className="col-lg-12">
                <Link to="/Organization" type="button" className="btn">
                  Ok
                </Link>
                &nbsp;&nbsp;&nbsp;
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
