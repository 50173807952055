import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import { Link } from "react-router-dom";

export default class AsAdminModel extends Component {
  render() {
    return (
      <div className="pop">
        <div className="popinnernormal" style={{ width: "34%", padding: "1%" }}>
          <form autoComplete="off">
            <h5 style={{ color: "rgb(36 66 42)", textAlign: "center" }}>
              Are you sure you want to Login As Admin?
            </h5>
            <br />
            <Link to="/Organization" className="btn">
              Yes
            </Link>{" "}
            <Link href onClick={this.props.close} className="btn">
              No
            </Link>
          </form>
        </div>
      </div>
    );
  }
}
