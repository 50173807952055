import React, { Component } from "react";
import CloseIcon from "@material-ui/icons/Close";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Aspuserlistchat from "../Common/Aspuserlistchat";
export default class ChatGrupPop extends Component {
  render() {
    var w = window.innerWidth;

    return (
      <>
        <div>
          <div className="pcoded-content">
            <div className="pcoded-inner-content">
              <div className="main-body chat-bg">
                <div className="">
                  <div id="" className="">
                    <div className="page-body">
                      <div className="row">
                        <div className="chat-box">
                          <div id="sidebar" className="users p-chat-user">
                            <div className="had-container">
                              <div
                                className="card card_main p-fixed users-main "
                                style={{ overflow: "scroll" }}
                              >
                                <div className="user-box">
                                  <div id="tsum-tabs">
                                    <main id="maintab">
                                      <br />

                                      <div className="right-icon-control">
                                        <input
                                          type="text"
                                          className="form-control  search-text"
                                          placeholder="Search Friend"
                                          onChange={this.GroupSearch}
                                        />
                                        <div className="form-icon">
                                          <i className="icofont icofont-search"></i>
                                        </div>
                                      </div>

                                      <div className="right-icon-control"></div>
                                      <br />

                                      <div
                                        className="media userlist-box"
                                        data-id="1"
                                        data-status="online"
                                        data-username="Josephin Doe"
                                        data-toggle="tooltip"
                                        data-placement="left"
                                        title="Josephin Doe"
                                      >
                                        <a className="media-left">
                                          <img
                                            className="media-object img-circle"
                                            src="assets/images/avatar-1.png"
                                            alt="Generic placeholder image"
                                          />
                                        </a>
                                        <div
                                          className="media-body"
                                          style={{ textAlign: "initial" }}
                                        >
                                          <div
                                            className="f-13 chat-header"
                                            style={{ color: "black" }}
                                          >
                                            {" "}
                                            firstName
                                          </div>
                                        </div>
                                        {/* <label className="checkboxChat">
                                            <input
                                              type="checkbox"
                                              className="checkbox__input_chat"
                                            />
                                            <span className="checkbox__inner_chat"></span>
                                          </label> */}
                                        <button
                                          className="button-30 sk-edit"
                                          type="button"
                                          style={{
                                            color: "#5035ce",
                                            fontWeight: "bold",
                                          }}
                                        >
                                          Add
                                        </button>
                                      </div>
                                    </main>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div id="styleSelector"></div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
