import React, { Component } from "react";

const reader = new FileReader();
export default class Tempfile extends Component {
  constructor(props) {
    super(props);

    this.state = {
      SelectedImages: [],
      Imagesname: [],
    };
    this.deleteimg = this.deleteimg.bind(this);
    this.sub = this.sub.bind(this);
    this.mysub = this.mysub.bind(this);
  }

  handleImage = (e) => {
    var Images = this.state.SelectedImages;
    var name = this.state.Imagesname;
    for (var i = 0; i < e.target.files.length; i++) {
      Images.push(e.target.files[i]);
      name.push(e.target.files[i].name);
      var size = "";
      if (e.target.files[i].size >= 1073741824) {
        e.target.files[i].size =
          (e.target.files[i].size / 1073741824).toFixed(2) + " GB";
      } else if (e.target.files[i].size >= 1048576) {
        size = (e.target.files[i].size / 1048576).toFixed(2) + " MB";
      } else if (e.target.files[i].size >= 1024) {
        size = (e.target.files[i].size / 1024).toFixed(2) + " KB";
      } else if (e.target.files[i].size > 1) {
        size = e.target.files[i].size + "bytes";
      } else if (e.target.files[i].size == 1) {
        size = e.target.files[i].size + " byte";
      } else {
        size = "0 bytes";
      }
    }
    this.setState({ Images });
    this.setState({ name });
  };

  formatBytes(bytes, decimals = 2) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  }

  deleteimg = (i) => {
    var index = this.state.Imagesname.indexOf(i);
    var dd = this.state.SelectedImages;
    dd = dd.splice(index, 1);
    var img = this.state.Imagesname;
    img = img.splice(index, 1);
    this.setState({ dd });
    this.setState({ img });
  };

  sub = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    for (var i = 0; i < this.state.SelectedImages.length; i++) {
      data.append("Pic", this.state.SelectedImages[i]);
    }
  };

  mysub = (e) => {
    e.preventDefault();
    const data = new FormData();
    for (var i = 0; i < this.state.SelectedImages.length; i++) {
      data.append("Pic", this.state.SelectedImages[i]);
    }
    //
    var ls = JSON.stringify([...data]);
    localStorage.setItem("Data", ls);
  };

  render() {
    return (
      <div>
        <input type="file" onInputCapture={this.handleImage} multiple />
        <br />
        {/* {this.state.SelectedImages.map(Team =>   <h1>{Team.name}</h1>)}  */}
        {this.state.SelectedImages.map((image) => (
          <>
            <span className="" onClick={() => this.deleteimg(image.name)}>
              <i className="icofont icofont-close-squared-alt clsbtn"></i>
            </span>
            <img
              height="150px"
              width="150px"
              src={URL.createObjectURL(image)}
            />
          </>
        ))}
        <button onClick={this.mysub}>submit</button>
        <form onSubmit={this.sub}>
          {/* <input type="file"  name="sample" multiple/><br/> */}
          <button type="submit">ss</button>
        </form>
      </div>
    );
  }
}
