import React, { Component } from "react";
export default class dummy extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }
  cipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const byteHex = (n) => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);

    return (text) =>
      text
        .split("")
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join("");
  };

  decipher = (salt) => {
    const textToChars = (text) => text.split("").map((c) => c.charCodeAt(0));
    const applySaltToChar = (code) =>
      textToChars(salt).reduce((a, b) => a ^ b, code);
    return (encoded) =>
      encoded
        .match(/.{1,2}/g)
        .map((hex) => parseInt(hex, 16))
        .map(applySaltToChar)
        .map((charCode) => String.fromCharCode(charCode))
        .join("");
  };

  componentDidMount() {
    // To create a cipher
    //const myCipher = this.cipher('mySecretSalt')
    //Then cipher any text:
    //To decipher, you need to create a decipher and use it:
    //const myDecipher = this.decipher('mySecretSalt')
  }
  render() {
    return <div></div>;
  }
}
